var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-list mb-11"},[_c('Breadcrumb',{attrs:{"items":_vm.breadCrumbs}}),_c('div',{staticClass:"pt-0 pt-md-1 pb-6"},[_c('v-container',[_c('h2',{staticClass:"align-center py-4"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('v-card',{staticClass:"py-0 py-md-6",attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"2","order-sm":"1","cols":"12","sm":"4","xl":"3"}},[_c('v-list',{staticClass:"warehouse-list-list",attrs:{"height":_vm.mapHeight}},_vm._l((_vm.warehouseList),function(warehouse){return _c('v-list-item',{key:warehouse.warehouseId,attrs:{"three-line":"","dense":""},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse)},"dblclick":function($event){return _vm.goToDetail(warehouse)}}},[_c('v-list-item-avatar',{attrs:{"rounded":"0","max-width":"24px","max-height":"34px","min-height":"24px","min-width":"24px"}},[_c('img',{attrs:{"src":"/pdv/pin.png","alt":"pin negozio"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(warehouse.name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(warehouse.address.address1)+","+_vm._s(warehouse.address.addressNumber)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(warehouse.address.city)+" ("+_vm._s(warehouse.address.province)+") ")])],1)],1)}),1)],1),_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"1","order-sm":"2","cols":"12","sm":"8","xl":"9"}},[_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{ lat: 45.870083, lng: 12.622737 },"zoom":_vm.$vuetify.breakpoint.xsOnly ? 6 : 7,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
              }}},[_vm._l((_vm.warehouseList),function(warehouse,i){return _c('GmapMarker',{key:warehouse.warehouseId,ref:'warehousMarker_' + warehouse.warehouseId,refInFor:true,attrs:{"position":_vm.google &&
                    new _vm.google.maps.LatLng(
                      warehouse.address.latitude,
                      warehouse.address.longitude
                    ),"clickable":true,"icon":"/pdv/pin.png"},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse, i)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false},"click":_vm.goToDetail}},[_vm._v(" "+_vm._s(_vm.selectedWarehouse)+" Contenuto ")])],2)],1)],1)],1),_c('div',{staticClass:"mt-8",domProps:{"innerHTML":_vm._s(_vm.pageContent)}}),_c('div',{staticClass:"mt-8 d-flex justify-center"},[_c('router-link',{staticClass:"secondary--text",attrs:{"to":{ name: 'Flyers' }}},[_vm._v(_vm._s(_vm.$t("storeLocator.linkFlyers")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }