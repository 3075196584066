<template>
  <div class="warehouse-list mb-11">
    <Breadcrumb :items="breadCrumbs" />
    <div class="pt-0 pt-md-1 pb-6">
      <v-container>
        <h2 class="align-center py-4">
          {{ pageTitle }}
        </h2>
        <v-card elevation="3" class="py-0 py-md-6">
          <v-row>
            <v-col
              class="py-0 py-md-3"
              order="2"
              order-sm="1"
              cols="12"
              sm="4"
              xl="3"
            >
              <v-list class="warehouse-list-list" :height="mapHeight">
                <v-list-item
                  v-for="warehouse in warehouseList"
                  :key="warehouse.warehouseId"
                  three-line
                  dense
                  @click="toggleInfoWindow(warehouse)"
                  @dblclick="goToDetail(warehouse)"
                >
                  <!-- @mouseenter="animateMarker(warehouse, true)"
                  @mouseleave="animateMarker(warehouse, false)" -->

                  <v-list-item-avatar
                    rounded="0"
                    max-width="24px"
                    max-height="34px"
                    min-height="24px"
                    min-width="24px"
                  >
                    <img src="/pdv/pin.png" alt="pin negozio" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ warehouse.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ warehouse.address.address1 }},{{
                        warehouse.address.addressNumber
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ warehouse.address.city }} ({{
                        warehouse.address.province
                      }})
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col
              class="py-0 py-md-3"
              order="1"
              order-sm="2"
              cols="12"
              sm="8"
              xl="9"
            >
              <GmapMap
                ref="mapRef"
                :center="{ lat: 45.870083, lng: 12.622737 }"
                :zoom="$vuetify.breakpoint.xsOnly ? 6 : 7"
                :style="{ width: mapWidth, height: mapHeight }"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: false
                }"
              >
                <GmapMarker
                  :ref="'warehousMarker_' + warehouse.warehouseId"
                  v-for="(warehouse, i) in warehouseList"
                  :key="warehouse.warehouseId"
                  :position="
                    google &&
                      new google.maps.LatLng(
                        warehouse.address.latitude,
                        warehouse.address.longitude
                      )
                  "
                  :clickable="true"
                  icon="/pdv/pin.png"
                  @click="toggleInfoWindow(warehouse, i)"
                />
                <gmap-info-window
                  :options="infoOptions"
                  :position="infoWindowPos"
                  :opened="infoWinOpen"
                  @closeclick.stop="infoWinOpen = false"
                  @click="goToDetail"
                >
                  {{ selectedWarehouse }}
                  Contenuto
                </gmap-info-window>
              </GmapMap>
            </v-col>
          </v-row>
        </v-card>
        <div class="mt-8" v-html="pageContent"></div>
        <div class="mt-8 d-flex justify-center">
          <router-link class="secondary--text" :to="{ name: 'Flyers' }">{{
            $t("storeLocator.linkFlyers")
          }}</router-link>
        </div>
      </v-container>
    </div>
  </div>
</template>
<style lang="scss">
.warehouse-list-list {
  height: 380px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: initial;
  }
  overflow-y: auto;

  .v-list-item__title {
    text-transform: uppercase;
    color: var(--v-secondary-base);
    font-size: 16px;
    font-weight: bold;
  }
  .v-list-item__subtitle {
    color: $text-color;
  }
  .v-avatar {
    padding: 2px;
    margin: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
#infoWindowDialog {
  padding: 10px;
  cursor: pointer;
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #515151;
  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--v-secondary-base);
  }
}
</style>
<script>
import category from "~/mixins/category";
import AddressService from "~/service/addressService";
import { gmapApi } from "vue2-google-maps";
import page from "~/mixins/page";

import Breadcrumb from "@/components/navigation/Breadcrumb";
export default {
  name: "WarehouseList",
  mixins: [category, page],
  props: { showList: { type: Boolean, default: true } },
  components: {
    Breadcrumb
  },
  data() {
    return {
      warehouseList: [],
      regionList: {},
      selectedRegionId: null,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "351px";
        case "sm":
          return "573px";
        case "md":
          return "563px";
        case "lg":
          return "754px";
        case "xl":
          return "1061px";
      }
      return "850px";
    },
    mapHeight() {
      return "480px";
    },
    breadCrumbs() {
      return [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        { to: { name: "WarehouseList" }, text: "Punti Vendita", exact: true }
      ];
    },
    pageContent() {
      return this.page && this.page.content ? this.page.content.rendered : "";
    },
    pageTitle() {
      return this.page && this.page.title ? this.page.title.rendered : "";
    }
  },
  methods: {
    async findWarehouse() {
      let data = await AddressService.findWarehouse({
        region_id: this.selectedRegionId
      });
      this.warehouseList = data.warehouses;
      this.regionList = data.facets.find(element => element.name == "region");
    },
    animateMarker(warehouse, status) {
      if (status) {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = this.google.maps.Animation.BOUNCE;
      } else {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = null;
      }
    },
    toggleInfoWindow: function(warehouse) {
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };
      let metaData = warehouse.metaData.warehouse_info;
      let phoneNumber = metaData && metaData.PHONE ? metaData.PHONE : " -- ";
      // let email = metaData && metaData.EMAIL ? metaData.EMAIL : " --- ";
      // let emailDelivery =
      // metaData && metaData.EMAIL_DELIVERY ? metaData.EMAIL_DELIVERY : " --- ";
      this.infoOptions.content =
        "<div id='infoWindowDialog' style='font-family: Poppins'>" +
        "<h3 class='d-flex align-center mb-3'><img class='mr-2' src='/pdv/pin.png' alt='pin negozio' />" +
        warehouse.name +
        "</h3>" +
        "<div class='mb-3'>" +
        warehouse.address.address1 +
        " " +
        warehouse.address.addressNumber +
        "<br/>" +
        warehouse.address.city +
        " (" +
        warehouse.address.province +
        ") " +
        warehouse.address.postalcode +
        "<br/>" +
        "Telefono: " +
        phoneNumber +
        "<br/>" +
        // "Email: <span class='text-decoration-underline'>" +
        // email +
        // "</span><br/>" +
        // "Email delivery: <span class='text-decoration-underline'>" +
        // emailDelivery +
        // "</span><br/>" +
        "</div>" +
        "<a class='secondary--text font-weight-bold text-decoration-none' target='system' href='https://www.google.it/maps/dir//" +
        warehouse.name +
        "@/" +
        warehouse.address.latitude +
        "," +
        warehouse.address.longitude +
        "'>COME ARRIVARE</a>" +
        "</div>";

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }

      this.$nextTick(() => {
        // let infoWindow = document.querySelectorAll('[role="dialog"]')[0];
        let infoWindow = document.getElementById("infoWindowDialog");
        if (infoWindow) {
          infoWindow.addEventListener(
            "click",
            () => {
              this.goToDetail(warehouse);
            },
            false
          );
        }
      });
    },
    goToDetail(warehouse) {
      console.log(warehouse.name);
      this.$router.push({
        name: "WarehouseDetail",
        params: { warehouseId: warehouse.warehouseId }
      });
    }
  },
  mounted() {
    this.fetchPage("punti-vendita");
    this.findWarehouse();
  }
};
</script>
